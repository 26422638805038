<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<planning_nav :site="site" :is_planning_complete="is_planning_complete" />				

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">
					
					<div :style="!hide_comments ? 'flex: 0 0 270px;' : 'flex: 0 0 10px;'">
						<div class="h-100 border-end position-relative preview-settings" style="z-index: 1">
							<div v-if="!hide_comments" class="w-100 h-100 overflow-auto p-2">
								<comments :workspace="workspace" :site="site" :is_sitemap="true" />
							</div>
						</div>
					</div>

					<div class="overflow-hidden flex-grow-1">
						<div class="w-100 h-100 position-relative pt-4 overflow-hidden">
							
							<sitemap :key="'sitemap-' + version" />

						</div>
					</div>
				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import sitemap from '@/components/sitemap'
import planning_nav from '@/components/planning_nav'
import comments from '@/components/comments'
import site_service from '@/services/site_service'

export default {
	name: 'site.planning',

	props: {
		modelValue: [Object],
		workspace: [Object],
		is_planning_complete: Boolean,
		is_design_complete: Boolean,
		is_build_complete: Boolean,
		is_launch_complete: Boolean
	},

	emits: [
		'update:modelValue',
	],

	components: {
		sitemap,
		planning_nav,
		comments
	},

	data() {
		return {
			loading: false,
			pages_loading: false,
			pages_loaded: false,
			site: null,
			version: 0,
			hide_comments: false
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit( question )
		{
			this.loading = true 

			this.reset_error()

			this.site.question = question

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				this.version++

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},
	}
}
</script>